import React from "react"
import SEO from "../components/seo"

import config from '../config'

const NotFoundPage = () => {
  if (typeof window !== 'undefined') {
    window.location.replace(config.mainPath)
  }
  return null
}

export default NotFoundPage
